import gql from 'graphql-tag'

export const PAGINATED_EVENT_LIST_QUERY = gql`
query paginatedEvents($after: String, $eventTypeName: String, $search: String, $startDate: DateTime, 
                      $endDate: DateTime, $orderBy: String, $nameContains: String, $hidden: Boolean){
  paginatedEvents (first: 30, search: $search, after: $after, eventType_Name: $eventTypeName, 
                   scheduledDate_Lt: $endDate, scheduledDate_Gt: $startDate, orderBy: $orderBy, name_Icontains: $nameContains,
                   hidden: $hidden){
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        userBookmark {
          id
        }
        eventType {
          id
          name
          category
        }
        thumbnail
        url
        scheduledDate
        duration
        description
        embedUrl
        startTime
        endTime
        vimeoVideoId
        facebookPermalinkId
        openAccess
        createdAt
        content {
          file
        }
        session {
          name
          vimeoLiveId
        }
        profile {
          profilePic
          user {
            firstName
            lastName
          }
        }
        useractivitySet {
          edges {
            node {
              id
              status
            }
          }
        }
      }
    }
  }
}
`

export const EVENT_QUERY = gql`
query event($id: ID!) {
  event(id: $id) {
    id
    name
    userBookmark {
      id
    }
    eventType {
      id
      name
      category
    }
    thumbnail
    instructor {
      id
    }
    url
    scheduledDate
    duration
    timeRequired
    description
    embedUrl
    startTime
    endTime
    vimeoVideoId
    youtubeId
    videoStartTime
    facebookPermalinkId
    registrationLink
    openAccess
    createdAt
    location
    content {
      name
      file
    }
    session {
      name
      vimeoLiveId
    }
    profile {
      profilePic
      user {
        firstName
        lastName
      }
    }
    useractivitySet {
      edges {
        node {
          id
          status
        }
      }
    }
  }
}
`