import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store'
import vuetify from './plugins/vuetify';
import { apolloProvider } from './graphql/apollo'
import './plugins/vee-validate'

Vue.use(require('vue-moment'));

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  apolloProvider,
  router,
  store,
  vuetify,
}).$mount('#app')
