
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md', // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#3ADEB4',
        secondary: '#211256',
        selection: '#FFC50B',
        error: '#FC3A64',
        white: '#FFFFFF',
        exercise: '#FD89A2',
        nutrition: '#3ADEB4',
        mindset: '#FFD13C',
        'text-primary': '#211256',
        grey: '#DFE2E5',
        'grey-navy': '#656077',
        'text-grey': '#4F4F4F',
        'mid-grey': '#858585',
        'background-grey': '#F7F7F9',
        'font-color': '#2c3e50',
        'light-blue': '#F7F7F9',
        'complete-green': '#15b775',
        'non-compliant': '#fc4b8b',
        'green': '#2EB8A7',
        'dark-yellow': '#c29400',
        'light-yellow': '#FFF3CE',
        'dark-red': '#FC3A64',
        'light-red': '#FED7E0',
        'dark-green': '#07A693',
        'light-green': '#D0F6F2',
        'dark-blue': '#4E4278',
        'background-blue': '#D3D0DD',
      }
    }
  }
});

