export default {
  name: "BirthdayMixin",
  methods: {
    leapBirthday(dateOfBirth) {
      if (dateOfBirth) {
        let dob = new Date(dateOfBirth);

        if (dob.getMonth() == 1 && dob.getDate() == 29) {
          return true;
        }
      }

      return false;
    },
    birthday(dateOfBirth) {
      if (dateOfBirth) {
        let dob = new Date(dateOfBirth);
        let today = this.$moment();

        let year = new Date().getFullYear();
        let birthday = new Date(year, dob.getMonth(), dob.getDate());
        let lastBirthday = new Date(year - 1, dob.getMonth(), dob.getDate());
        let nextBirthday = new Date(year + 1, dob.getMonth(), dob.getDate());

        let birthdayDiff = today
          .startOf("day")
          .diff(this.$moment(birthday), "days");
        let lastBirthdayDiff = today
          .startOf("day")
          .diff(this.$moment(lastBirthday), "days");
        let nextBirthdayDiff = today
          .startOf("day")
          .diff(this.$moment(nextBirthday), "days");

        //handle end of year / beginning of year calculations without worrying about leap years etc
        let diff = null;
        if (birthdayDiff > -8 && birthdayDiff < 4) diff = birthdayDiff;
        else if (lastBirthdayDiff > -8 && lastBirthdayDiff < 4) {
          diff = lastBirthdayDiff;
          birthday = lastBirthday;
        } else if (nextBirthdayDiff > -8 && nextBirthdayDiff < 4) {
          diff = nextBirthdayDiff;
          birthday = nextBirthday;
        }

        if (diff == 0) return { text: "Today", tense: "Today" };
        else if (diff > 0) {
          let text = "";
          if (diff == 1) text = "Yesterday";
          else if (diff <= 3)
            //in the last week
            text = `${this.$moment(birthday).format("dddd")} past`;

          return { text: text, tense: "Past" };
        }
        else if (diff < 0) {
          let text = "";
          if (diff == -1) text = "Tomorrow";
          else if (diff >= -7)
            //in the next week
            text = `${this.$moment(birthday).format("dddd")} coming`;

            return { text: text, tense: "Future" };
        }
      }
    },
  },
};
