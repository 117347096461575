<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark> </v-app-bar> -->
    <NavBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/shared/NavBar'

export default {
  name: "App",
  components: {
    NavBar
  },

  data: () => ({
    //
  }),
};
</script>
