import gql from 'graphql-tag'

export const REPORT_QUERY = gql`
query eventTracker($eventId: ID!, $after: String) {
  eventTracker(eventId: $eventId){
   id
   clubAttendees
   lifeAttendees
   oneOnOneAttendees
   usereventsessionSet(first: 10, after: $after){
     pageInfo {
       endCursor
       hasNextPage
     }
     edges {
       node {
         id
         susbcription {
           id
           product {
             id
             name
             type
           }
         }
         lastEarnedBadge {
           id
           createdAt
           badge {
             id
             title
           }
         }
         challenge {
           id 
           challenge {
             id 
             title
             endDate
           }
         }
         course {
           id
           course {
             id
             title
           }
         }
         clientDots {
          exerciseDots
          nutritionDots
          mindsetDots
        }
         user {
           id
           firstName
           lastName
           dateJoined
           subscriptionblockSet {
            id
            startDate
            endDate
            block {
              id
              title
              type
            }
          }
           userProfile {
             id
             suburb
             dateOfBirth
             profilePicture
              country {
                name
              }
           }
         }
       }
     }
   }
 }
}`