<template>
  <div v-if="birthday(dateOfBirth)" class="pt-1">
    <v-chip :color="chipColor">
      <v-tooltip v-if="leapBirthday(dateOfBirth)" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="purple" v-bind="attrs" v-on="on" small class="mr-2">
            mdi-cake-variant
          </v-icon>
        </template>
        <span>Leap year birthday!</span>
      </v-tooltip>

      <v-icon v-else :color="cakeColor" small class="mr-2">
        mdi-cake-variant
      </v-icon>
      {{ birthdayText }}
    </v-chip>
  </div>
</template>

<script>
import BirthdayMixin from "@/components/shared/mixins/BirthdayMixin";

export default {
  name: "BirthdayChip",
  props: {
    dateOfBirth: {
      type: String,
      required: false,
    },
  },
  mixins: [BirthdayMixin],
  computed: {
    chipColor() {
      let tense = this.birthday(this.dateOfBirth).tense;
      let color = "#E9EAEA";

      switch (tense) {
        case "Today":
          color = "#D0F6F2";
          break;
        case "Past":
          color = "#E9EAEA";
          break;
        case "Future":
          color = "#FFF3CE";
          break;
      }

      return color;
    },
    cakeColor() {
      let tense = this.birthday(this.dateOfBirth).tense;
      let color = "#c29400";

      switch (tense) {
        case "Today":
          color = "#07A693";
          break;
        case "Past":
          color = "#747678";
          break;
        case "Future":
          color = "#c29400";
          break;
      }

      return color;
    },
    birthdayText() {
      return this.birthday(this.dateOfBirth).text;
    },
  },
};
</script>
