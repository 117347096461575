import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
mutation tokenAuth($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password){
    token
    user {
      id
      firstName
      lastName
      email
      isSuperuser
      teamLead {
        id
        name 
      }
    }
  }
}
`