<template>
  <v-container>
    <v-row>
      <v-col cols="12" align="center" justify="center">
        <h2>Welcome JEFF</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style>
</style>