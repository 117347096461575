<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" offset-md="4">
        <v-card class="mx-auto pa-3 mt-6 card-radius">
          <v-alert v-if="errorMessage" color="#FFF7DB">
            <span style="color: #bf9600"
              >Please enter a valid combination of email and password to get
              access.</span
            >
          </v-alert>

          <v-card-title class="pb-0 mb-1">
            <h2 class="font-weight-bold">Log in</h2>
          </v-card-title>
          <v-card-text>
            <ValidationObserver ref="observer">
              <v-form @submit.prevent="submit">
                <ValidationProvider
                  id="username"
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model.trim="username"
                    label="Email"
                    :error-messages="errors"
                    @keydown.space.prevent
                  />
                </ValidationProvider>

                <ValidationProvider
                  id="password"
                  v-slot="{ errors }"
                  name="password"
                  rules="required"
                >
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye'"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    :error-messages="errors"
                    @click:append="showPassword = !showPassword"
                  />
                </ValidationProvider>

                <v-btn
                  rounded
                  large
                  type="submit"
                  color="secondary"
                  class="mt-2"
                  width="200px"
                  :loading="buttonLoading"
                >
                  log in
                </v-btn>
              </v-form>
            </ValidationObserver>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <a
                  href="/forgot-password"
                  style="text-decoration: none"
                  class="secondary--text font-weight-bold body-2"
                  >Forgot Password?</a
                >
              </v-col>
              <v-col
                cols="12"
                style="border-top: 1px solid; border-top-color: #e5e5e5"
              >
                <a
                  href="https://help.fitnessfaculty.club/help-centre/"
                  style="text-decoration: none"
                  class="secondary--text font-weight-bold body-2"
                  >Help Centre
                </a>
                <span class="px-2" style="color: #aaa">•</span>
                <a
                  href="https://bit.ly/jeff_help"
                  style="text-decoration: none"
                  class="secondary--text font-weight-bold body-2"
                  >Contact Support
                </a>
              </v-col>
              <v-col
                cols="12"
                style="border-top: 1px solid; border-top-color: #e5e5e5"
              >
                <a
                  href="https://www.jeff.fitness/"
                  style="text-decoration: none"
                  class="secondary--text font-weight-bold body-2"
                  >Sign up for JEFF</a
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LOGIN_MUTATION } from "@/graphql/constants/authentication";
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      showPassword: false,
      buttonLoading: false,
    };
  },
  computed: {},
  created() {
    if (this.$route.query.error == 1) {
      this.errorMessage = "Access denied. Please login.";
    } else if (this.$route.query.error == 2) {
      this.errorMessage = "Session timed out";
    }
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.buttonLoading = true;
          this.login();
        }
      });
    },
    login() {
      const username = this.username.toLowerCase();
      const password = this.password;

      this.$apollo
        .mutate({
          mutation: LOGIN_MUTATION,
          variables: {
            username,
            password,
          },
        })
        .then((result) => {
          const login = result.data.tokenAuth;
          const accessToken = login.token;
          const tokenPayload = VueJwtDecode.decode(accessToken);
          const refreshToken = tokenPayload.exp;
          const user = login.user;

          this.$store
            .dispatch("login", { accessToken, refreshToken, user })
            .then(() => {
              this.redirectToNext();
            });
        })
        .catch((error) => {
          this.buttonLoading = false;
          if (error.message.includes("GraphQL error:")) {
            this.errorMessage = error.message
              .replace("GraphQL error:", "")
              .trim();
          } else {
            this.errorMessage =
              "Something went wrong, we're looking into it. Please try login again.";
            throw error;
          }
        });
    },
    redirectToNext() {
      var path = sessionStorage.getItem("redirectPath") || "";
      if (path != "") {
        sessionStorage.removeItem("redirectPath");
        this.$router.push(path);
      } else {
        this.$router.push("/events");
      }
      this.buttonLoading = false;
    },
  },
};
</script>
