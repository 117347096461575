<template>
  <div class="clock mb-5 pa-2" align="center">
    <span class="time white--text">{{ time }}</span>
    <div style="display: flex;" class="py-1">
      <a class="time-button px-2 py-1 dark-green--text" @click="start()">Start</a>
      <a class="time-button px-2 py-1 dark-red--text" @click="stop()">Stop</a>
      <a class="time-button px-2 py-1 grey--text" @click="reset()">Reset</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stopwatch",
  data: () => ({
    time: "00:00:00",
    timeBegan: null,
    timeStopped: null,
    stoppedDuration: 0,
    started: null,
    running: false,
    snackbar: false
  }),
  methods: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    start() {
      if (this.running) return;

      if (this.timeBegan === null) {
        this.reset();
        this.timeBegan = new Date();
      }

      if (this.timeStopped !== null) {
        this.stoppedDuration += new Date() - this.timeStopped;
      }

      this.started = setInterval(() => {
        var currentTime = new Date();
        var timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration);
        var hour = timeElapsed.getUTCHours();
        var min = timeElapsed.getUTCMinutes();
        var sec = timeElapsed.getUTCSeconds();

        this.time =
          this.zeroPrefix(hour, 2) + ":" + this.zeroPrefix(min, 2) + ":" + this.zeroPrefix(sec, 2);
      }, 10);
      this.running = true;
    },
    stop() {
      this.running = false;
      this.timeStopped = new Date();
      clearInterval(this.started);
    },
    reset() {
      this.running = false;
      clearInterval(this.started);
      this.stoppedDuration = 0;
      this.timeBegan = null;
      this.timeStopped = null;
      this.time = "00:00:00";
    },
    clockRunning() {
      var currentTime = new Date();
      var timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration);
      var hour = timeElapsed.getUTCHours();
      var min = timeElapsed.getUTCMinutes();
      var sec = timeElapsed.getUTCSeconds();

      this.time =
        this.zeroPrefix(hour, 2) + ":" + this.zeroPrefix(min, 2) + ":" + this.zeroPrefix(sec, 2);
    },
    zeroPrefix(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    }
  }
};
</script>

<style>
.time {
  font-size: 3.5em;
  margin: 0 auto;
}
.time-button {
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 1.2em;
  cursor: pointer;
}

.text {
  font-size: 1em;
}

.btn-container {
  display: flex;
}

.clock {
  background-color: #2b2626;
  border-radius: 20px;
}
</style>
