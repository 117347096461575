import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'
import { required, email, min, confirmed, is, numeric, regex, max, max_value, integer, min_value } from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en';

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', { ...required, message: en.messages['required'] })
extend('email', { ...email, message: en.messages['email'] })
extend('min', { ...min, message: en.messages['min'] })
extend('confirmed', { ...confirmed, message: en.messages['confirmed'] })
extend('is', { ...is, message: en.messages['is'] })
extend('numeric', { ...numeric, message: en.messages['numeric'] })
extend('max', { ...max, message: en.messages['max'] })
extend('regex', { ...regex, message: en.messages['regex'] })
extend('max_value', { ...max_value, message: en.messages['max_value'] })
extend('integer', { ...integer, message: en.messages['integer'] })
extend('min_value', { ...min_value, message: en.messages['min_value'] })


setInteractionMode('eager')
