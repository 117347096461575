export default {
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    user: localStorage.getItem('user') || null,
  },
  mutations: {
    authSuccess(state, { accessToken, refreshToken, user }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.user = JSON.stringify(user);
    },
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
    },
  },
  actions: {
    login({ commit, getters }, { accessToken, refreshToken, user = getters.user }) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));
      commit('authSuccess', { accessToken, refreshToken, user });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        commit('logout');
        resolve();
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.user,
    user: state => JSON.parse(state.user),
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
  }
}
