<template>
  <v-container
    class="text-center"
    fill-height
  >
    <v-row align="center">
      <v-col>
        <h1 class="display-1 primary--text">Whoops, 404</h1>

        <p>The page you were looking for does not exist</p>

        <v-btn
          :to="`/`"
          color="primary"
          outlined
        >
          Get me back to the good stuff!
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'FourOhFour',
    created () {
      this.$ssrContext && this.$ssrContext.res.status(404)
    },
  }
</script>