<template>
  <div>
    <v-navigation-drawer
      v-if="isLoggedIn"
      v-model="drawer"
      temporary
      right
      fixed
    >
      <v-list>
        <v-list-item to="/events">
          <v-list-item-action>
            <v-icon>mdi-calendar-blank-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Events</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app light height="45" align="center" justify="center">
      <v-toolbar-title>
        <a @click="relaod">
          <v-img
            alt="JEFF Logo"
            class="shrink"
            contain
            min-width="80"
            src="@/assets/jeff_home_logo.png"
            width="80"
          />
        </a>
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="isLoggedIn"
        rounded
        :outlined="true"
        @click.stop="drawer = !drawer"
      >
        menu
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.getters.user;
    },
    teamLead() {
      return this.$store.getters.user.teamLead?.length > 0;
    },
    superUser() {
      return this.$store.getters.user.isSuperuser;
    },
  },
  methods: {
    relaod() {
      window.location.reload(true);
    },
    logout() {
      this.drawer = false;
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style>
</style>