var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"menu1",attrs:{"offset-y":"","max-width":"290px","min-width":"290px","close-on-content-click":false,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Start Date","prepend-icon":"mdi-calendar"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"menu2",attrs:{"offset-y":"","max-width":"290px","min-width":"290px","close-on-content-click":false,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"End Date","prepend-icon":"mdi-calendar"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),(_vm.loading)?_c('div',{staticClass:"text-center ma-12"},[_c('v-progress-circular',{attrs:{"indeterminate":_vm.loading,"color":"blue"}})],1):_c('div',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","align":"center"}},[_c('v-card',[_c('v-tabs',{staticClass:"selected-tabs-active",attrs:{"centered":"","background-color":"tabs","color":"secondary","active-class":"selected-tab-active","slider-size":"0"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"events"},[_vm._v(" Events ")]),_c('v-tabs-items',{staticClass:"py-2",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"events"},_vm._l((_vm.paginatedEvents.edges),function(event){return _c('v-card',{key:event.node.id,staticClass:"pa-1 mx-2 my-3 card-radius dashboard-card",attrs:{"to":{
                    name: 'Event',
                    params: { eventId: _vm.getId(event.node.id) }
                  }}},[_c('v-toolbar',{staticClass:"pa-0 ma-0",attrs:{"flat":""}},[_c('span',{staticClass:"grey-navy--text"},[_vm._v(_vm._s(_vm._f("moment")(event.node.scheduledDate,"YYYY-MM-DD HH:mm"))+" "),(_vm.eventPassed(event.node.scheduledDate))?_c('v-icon',{staticClass:"mb-1"},[_vm._v("mdi-history")]):_vm._e()],1)]),_c('v-list',{staticClass:"pa-0 ma-0",staticStyle:{"background-color":"#fff"}},[_c('v-list-item',{attrs:{"align":"left"}},[_c('v-list-item-avatar',{staticClass:"mt-0 pt-0",attrs:{"size":"80"}},[(event.node.thumbnail || event.node.profile)?_c('v-img',{attrs:{"src":event.node.thumbnail
                              ? event.node.thumbnail
                              : event.node.profile && event.node.profile.profilePic}}):_vm._e()],1),_c('v-list-item-content',{staticClass:"card-list-title"},[_c('v-list-item-title',[_vm._v(_vm._s(event.node.name))])],1)],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }