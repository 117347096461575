<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <v-col v-if="showCase" cols="12">
          <v-card>
            <v-card-text>
              <h1 class="dark-blue--text">Featured</h1>
              <br />
              <h3>Updates every 10 seconds</h3>
            </v-card-text>
            <v-card-text>
              <v-card>
                <v-card-title class="white--text">
                  <v-list-item class="grow">
                    <v-list-item-avatar size="80" color="grey darken-3">
                      <v-img
                        class="elevation-6"
                        alt=""
                        :src="showCase.user.userProfile.profilePicture"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <h2>
                          {{ showCase.user.firstName }}
                          {{ showCase.user.lastName }}
                        </h2>
                        <h2>
                          {{ showCase.user.userProfile.suburb }}
                          {{
                            showCase.user.userProfile.country
                              ? ", " + showCase.user.userProfile.country.name
                              : ""
                          }}
                        </h2>
                        <birthday-chip :date-of-birth="showCase.user.userProfile.dateOfBirth" />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-title>

                <v-card-text>
                  <v-chip
                    v-if="showCase.susbcription"
                    :color="
                      showCase.susbcription.product.type == 'JEFF_MEMBER'
                        ? 'light-green'
                        : 'light-red'
                    "
                  >
                    <div v-if="showCase.susbcription.product.type == 'JEFF_MEMBER'">
                      <span class="dark-green--text headline">
                        JEFF Life
                      </span>
                    </div>
                    <div v-else-if="showCase.susbcription.product.type == 'CLUB'">
                      <span class="dark-green--text headline">
                        Club
                      </span>
                    </div>
                    <div v-else>
                      <span class="dark-red--text headline">
                        ONE-ON-ONE
                      </span>
                    </div>
                  </v-chip>
                  <div class="pt-5">
                    <p class="small-font">
                      <b>Joined JEFF:</b>
                      {{ $moment().diff($moment(showCase.user.dateJoined), "days") }} days ago
                    </p>
                    <p v-if="showCase.lastEarnedBadge" class="small-font">
                      <b>Badge:</b>
                      {{ $moment().diff($moment(showCase.lastEarnedBadge.createdAt), "days") }}
                      days ago &#8594;
                      {{ showCase.lastEarnedBadge.badge.title }}
                    </p>
                    <p v-if="showCase.user.subscriptionblockSet.length > 0" class="small-font">
                      <b> Blocks:</b>
                      <span v-for="sub in showCase.user.subscriptionblockSet" :key="sub.id">
                        <template v-if="sub.block.type == 'CHALLENGE'">
                          {{ $moment(sub.endDate) >= $moment() ? "In progress" : "Finished" }}
                          &#8594;
                          {{ sub.block.title }}
                        </template>
                      </span>
                    </p>
                    <h2 class="grey-navy--text">Dots this month</h2>
                    <br />
                    <v-row>
                      <v-col cols="4" align="center">
                        <h3 class="grey-navy--text">Exercise</h3>
                        <v-avatar color="exercise" class="mt-2" size="60">
                          <span class="white--text headline">{{
                            showCase.clientDots.exerciseDots || 0
                          }}</span>
                        </v-avatar>
                      </v-col>
                      <v-col cols="4" align="center">
                        <h3 class="grey-navy--text">Nutrition</h3>
                        <v-avatar color="nutrition" class="mt-2" size="60">
                          <span class="white--text headline">{{
                            showCase.clientDots.nutritionDots || 0
                          }}</span>
                        </v-avatar>
                      </v-col>
                      <v-col cols="4" align="center">
                        <h3 class="grey-navy--text">Mindset</h3>
                        <v-avatar color="mindset" class="mt-2" size="60">
                          <span class="white--text headline">{{
                            showCase.clientDots.mindsetDots || 0
                          }}</span>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <h1 class="dark-blue--text">Users who have joined</h1>
            </v-card-text>
            <v-card-text>
              <v-card v-for="item in feed" :key="item.node.id" class="mb-3">
                <v-card-title class="white--text">
                  <v-list-item class="grow">
                    <v-list-item-avatar size="80" color="grey darken-3">
                      <v-img
                        class="elevation-6"
                        alt=""
                        :src="item.node.user.userProfile.profilePicture"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <h2>
                          {{ item.node.user.firstName }}
                          {{ item.node.user.lastName }}
                        </h2>
                        <h2>
                          {{ item.node.user.userProfile.suburb }}
                          {{
                            item.node.user.userProfile.country
                              ? ", " + item.node.user.userProfile.country.name
                              : ""
                          }}
                        </h2>
                        <birthday-chip :date-of-birth="item.node.user.userProfile.dateOfBirth" />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-title>

                <v-card-text>
                  <v-chip
                    v-if="item.node.susbcription"
                    :color="
                      item.node.susbcription.product.type == 'JEFF_MEMBER'
                        ? 'light-green'
                        : 'light-red'
                    "
                  >
                    <div v-if="item.node.susbcription.product.type == 'JEFF_MEMBER'">
                      <span class="dark-green--text headline">
                        JEFF Life
                      </span>
                    </div>
                    <div v-else-if="showCase.susbcription.product.type == 'CLUB'">
                      <span class="dark-green--text headline">
                        Club
                      </span>
                    </div>

                    <div v-else>
                      <span class="dark-red--text headline">
                        ONE-ON-ONE
                      </span>
                    </div>
                  </v-chip>
                  <div class="pt-5">
                    <p class="small-font">
                      <b>Joined JEFF:</b>
                      {{ $moment().diff($moment(item.node.user.dateJoined), "days") }} days ago
                    </p>
                    <p v-if="item.node.lastEarnedBadge" class="small-font">
                      <b>Badge:</b>
                      {{ $moment().diff($moment(item.node.lastEarnedBadge.createdAt), "days") }}
                      days ago &#8594;
                      {{ item.node.lastEarnedBadge.badge.title }}
                    </p>
                    <p v-if="item.node.user.subscriptionblockSet.length > 0" class="small-font">
                      <b> Blocks:</b>
                      <span v-for="sub in item.node.user.subscriptionblockSet" :key="sub.id">
                        <template v-if="sub.block.type == 'CHALLENGE'">
                          {{ $moment(sub.endDate) >= $moment() ? "In progress" : "Finished" }}
                          &#8594;
                          {{ sub.block.title }}
                        </template>
                      </span>
                    </p>
                    <h2 class="grey-navy--text">Dots this month</h2>
                    <br />
                    <v-row>
                      <v-col cols="4" align="center">
                        <h3 class="grey-navy--text">Exercise</h3>
                        <v-avatar color="exercise" class="mt-2" size="60">
                          <span class="white--text headline">{{
                            item.node.clientDots.exerciseDots || 0
                          }}</span>
                        </v-avatar>
                      </v-col>
                      <v-col cols="4" align="center">
                        <h3 class="grey-navy--text">Nutrition</h3>
                        <v-avatar color="nutrition" class="mt-2" size="60">
                          <span class="white--text headline">{{
                            item.node.clientDots.nutritionDots || 0
                          }}</span>
                        </v-avatar>
                      </v-col>
                      <v-col cols="4" align="center">
                        <h3 class="grey-navy--text">Mindset</h3>
                        <v-avatar color="mindset" class="mt-2" size="60">
                          <span class="white--text headline">{{
                            item.node.clientDots.mindsetDots || 0
                          }}</span>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
      <v-col cols="4">
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <Stopwatch />
              <v-card>
                <v-card-text>
                  <v-list style="background-color: #fff" class="pa-0 ma-0">
                    <v-list-item align="left">
                      <v-list-item-avatar size="80" class="mt-0 pt-0">
                        <v-img
                          v-if="event.thumbnail || event.profile"
                          :src="
                            event.thumbnail
                              ? event.thumbnail
                              : event.profile && event.profile.profilePic
                          "
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content class="dark-blue--text ">
                        <h3>{{ event.name }}</h3>
                        <h2 class="pt-2">{{ event.scheduledDate | moment("MMM, Do YYYY") }}</h2>
                        <h2 class="pt-2">{{ event.scheduledDate | moment("HH:mm") }}</h2>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="eventTracker">
            <v-col cols="6">
              <v-card>
                <v-card-title> JEFF life </v-card-title>
                <v-card-text align="center">
                  <v-avatar color="light-green" size="90">
                    <span class="dark-green--text headline">{{ eventTracker.lifeAttendees }}</span>
                  </v-avatar>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title> One on one </v-card-title>
                <v-card-text align="center">
                  <v-avatar color="light-red" size="90">
                    <span class="dark-red--text headline">{{
                      eventTracker.oneOnOneAttendees
                    }}</span>
                  </v-avatar>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title>Club </v-card-title>
                <v-card-text align="center">
                  <v-avatar color="light-yellow" size="90">
                    <span class="dark-yellow--text headline">{{ eventTracker.clubAttendees }}</span>
                  </v-avatar>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <h4>No data for this event</h4>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EVENT_QUERY } from "@/graphql/constants/event/event";
import { REPORT_QUERY } from "@/graphql/constants/event/tracker";
import BirthdayChip from "@/components/shared/BirthdayChip";
import Stopwatch from "@/components/shared/Stopwatch";

export default {
  name: "Event",
  components: {
    BirthdayChip,
    Stopwatch
  },
  data: () => ({
    loading: true,
    event: {},
    lastRecord: "",
    after: "",
    eventTracker: null,
    feed: [],
    userId: [],
    showCase: null
  }),
  methods: {
    pollData() {
      // cant use query polling due to the cursor changing;
      setInterval(() => {
        this.fecthMore();
      }, 10000);
    },
    startFeaturing() {
      this.handleUserShowCase();
      setInterval(() => {
        this.handleUserShowCase();
      }, 20000);
    },
    handleUserShowCase() {
      if (this.eventTracker) {
        var items = this.feed.filter(user => !user.seen);
        var itemCount = items.length;
        if (itemCount != 0) {
          var index = Math.floor(Math.random() * itemCount + 1) - 1;
          items[index]["seen"] = true;
          this.showCase = items[index].node;
        }
      }
    },
    fecthMore() {
      if (this.after == this.lastRecord) {
        this.$apollo.queries.eventTracker.refetch();
      } else {
        this.after = this.lastRecord;
      }
    }
  },
  apollo: {
    event: {
      query: EVENT_QUERY,
      variables() {
        return {
          id: this.$route.params.eventId
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loading = false;
        }
      }
    },
    eventTracker: {
      query: REPORT_QUERY,
      variables() {
        return {
          eventId: this.$route.params.eventId,
          after: this.after
        };
      },
      result(response) {
        if (response.data) {
          this.loading = false;
          if (response.data.eventTracker) {
            if (this.after == "") {
              setTimeout(() => {
                this.pollData();
                this.startFeaturing();
              }, 1000);
            }

            response.data.eventTracker.usereventsessionSet.edges.forEach(user => {
              if (this.feed.length == 0) {
                this.feed = [user];
              } else {
                let id = user.node.id;
                //prevent user duplication
                let records = this.feed.filter(item => item.node.id == id);
                if (records.length == 0) {
                  var oldFeed = this.feed;
                  this.feed = [user, ...oldFeed];
                }
              }
            });

            if (
              this.lastRecord == "" ||
              response.data.eventTracker.usereventsessionSet.pageInfo.hasNextPage
            ) {
              this.lastRecord = response.data.eventTracker.usereventsessionSet.pageInfo.endCursor;
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.small-font {
  font-size: 18px;
}
</style>
