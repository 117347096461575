<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="4">
        <v-menu
          ref="menu1"
          v-model="menu1"
          offset-y
          max-width="290px"
          min-width="290px"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              readonly
              v-bind="attrs"
              v-on="on"
              label="Start Date"
              prepend-icon="mdi-calendar"
            ></v-text-field>
          </template>
          <v-date-picker v-model="startDate"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-menu
          ref="menu2"
          v-model="menu2"
          offset-y
          max-width="290px"
          min-width="290px"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              readonly
              v-bind="attrs"
              v-on="on"
              label="End Date"
              prepend-icon="mdi-calendar"
            ></v-text-field>
          </template>
          <v-date-picker v-model="endDate"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <div v-if="loading" class="text-center ma-12">
      <v-progress-circular :indeterminate="loading" color="blue" />
    </div>
    <div v-else>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" align="center">
          <v-card>
            <v-tabs
              v-model="tab"
              centered
              background-color="tabs"
              color="secondary"
              active-class="selected-tab-active"
              slider-size="0"
              class="selected-tabs-active"
            >
              <v-tab key="events"> Events </v-tab>

              <v-tabs-items v-model="tab" class="py-2">
                <v-tab-item key="events">
                  <v-card
                    v-for="event in paginatedEvents.edges"
                    :key="event.node.id"
                    :to="{
                      name: 'Event',
                      params: { eventId: getId(event.node.id) }
                    }"
                    class="pa-1 mx-2 my-3 card-radius dashboard-card"
                  >
                    <v-toolbar flat class="pa-0 ma-0">
                      <span class="grey-navy--text"
                        >{{ event.node.scheduledDate | moment("YYYY-MM-DD HH:mm") }}
                        <v-icon class="mb-1" v-if="eventPassed(event.node.scheduledDate)"
                          >mdi-history</v-icon
                        ></span
                      >
                    </v-toolbar>
                    <v-list style="background-color: #fff" class="pa-0 ma-0">
                      <v-list-item align="left">
                        <v-list-item-avatar size="80" class="mt-0 pt-0">
                          <v-img
                            v-if="event.node.thumbnail || event.node.profile"
                            :src="
                              event.node.thumbnail
                                ? event.node.thumbnail
                                : event.node.profile && event.node.profile.profilePic
                            "
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content class="card-list-title">
                          <v-list-item-title>{{ event.node.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { PAGINATED_EVENT_LIST_QUERY } from "../../graphql/constants/event/event";

export default {
  name: "EventList",
  data: () => ({
    loading: true,
    tab: "events",
    paginatedEvents: {},
    startDate: null,
    endDate: null,
    menu1: false,
    menu2: false
  }),
  methods: {
    eventPassed(date) {
      var dt = new Date(date);
      return dt.setHours(dt.getHours() + 1) < new Date();
    },
    getId(base64string) {
      let decoded = atob(base64string).split(":");
      if (decoded[1]) return decoded[1];
      return "";
    }
  },
  apollo: {
    paginatedEvents: {
      query: PAGINATED_EVENT_LIST_QUERY,
      variables() {
        let start = this.startDate ? this.$moment(this.startDate) : null;
        let end = this.endDate ? this.$moment(this.endDate) : null;

        return {
          startDate: start,
          endDate: end
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style scoped></style>
