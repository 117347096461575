import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import VueApollo from 'vue-apollo'
import { store } from '../store'
import { createHttpLink } from 'apollo-link-http'

const httpLink = new createHttpLink({
  // URL to graphql server, you should use an absolute URL here
  uri: process.env.VUE_APP_API_ENDPOINT + '/graphql/'
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const accessToken = localStorage.getItem('accessToken') || store.getters.accessToken
  operation.setContext({
    headers: {
      Authorization: accessToken ? `JWT ${accessToken}` : null,
      'frontend-context': 'Coaching'
    }
  })

  return forward(operation)
})

const link = authMiddleware.concat(httpLink)

// create the apollo client
const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

// install the vue plugin
Vue.use(VueApollo)

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})
