import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store'

import Login from '../components/authentication/Login'
import Home from '../components/Home'
import PageNotFound from '../components/404'
import EventList from '../components/event/EventList'
import EventPage from '../components/event/Event'


Vue.use(VueRouter)

let router = new VueRouter({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/events',
    name: 'EventList',
    component: EventList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/events/:eventId',
    name: 'Event',
    component: EventPage,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  // PageNotFound should always be last
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  }
  ]
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Handle redirecting to /login if not logged in
    if (store.getters.isLoggedIn) {
      
      next()
    } else {
      sessionStorage.setItem('redirectPath', to.path)
      next({
        path: '/login',
        query: { error: 1 }
      })
    }
  } if (to.matched.some(record => record.meta.guest)) {
    if (store.getters.isLoggedIn) {
      var path = sessionStorage.getItem('redirectPath') || ''
      if (path != '') {
        sessionStorage.removeItem('redirectPath')
        next(path)
      } else {
        next('/events')
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

router.onError(error => {
  if (/loading.* chunk .* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router;
